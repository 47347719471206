import { graphql } from 'gatsby'
import PageLayout from '../components/PageLayout/PageLayout'
import SubpageHeader from '../components/SubpageHeader/SubpageHeader'
import { getMetaTags } from '../utils/metaTags'
import FeaturesZoneRenderer from '../zones/FeaturesZoneRenderer'
import { ReactComponent as PlanetImpactTitle } from '../../static/images/planet-impact.svg'
import { PageHorizontalNav } from '../components/PageHorizontalNav/PageHorizontalNav'

export default function LayoutFeaturesPlanet({ pageContext, data, location }) {
  const zones = data.strapiGQL.feature.contentZones
  return (
    <PageLayout theme="carbonTracking">
      <PageHorizontalNav parent="features" theme="planetImpact" />
      <SubpageHeader
        title={<PlanetImpactTitle style={{ width: '100%' }} />}
        summary={pageContext.subtitle}
        metaTags={getMetaTags(pageContext)}
      />
      {zones && <FeaturesZoneRenderer zones={zones} />}
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      feature(id: $id) {
        ...StrapiGQL_FeaturesWithContentZones
      }
    }
  }
`
